import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88')
];

export const server_loads = [0,4,5,7];

export const dictionary = {
		"/": [~13],
		"/admin": [~25,[3]],
		"/admin/client-onboarding": [26,[3]],
		"/admin/clients": [~27,[3]],
		"/admin/clients/[clientId]": [~28,[3,4]],
		"/admin/clients/[clientId]/employees": [~29,[3,4]],
		"/admin/clients/[clientId]/employees/[employeeId]": [~30,[3,4,5]],
		"/admin/clients/[clientId]/invoices": [~31,[3,4]],
		"/admin/clients/[clientId]/orders": [32,[3,4]],
		"/admin/clients/[clientId]/profile": [~33,[3,4]],
		"/admin/clients/[clientId]/subscriptions": [~34,[3,4]],
		"/admin/clients/[clientId]/units": [35,[3,4]],
		"/admin/invoices": [~36,[3,6]],
		"/admin/invoices/draft": [~37,[3,6]],
		"/admin/invoices/open": [~38,[3,6]],
		"/admin/invoices/overdue": [~39,[3,6]],
		"/admin/jobs": [40,[3]],
		"/admin/orders": [~41,[3]],
		"/admin/orders/[status]": [~42,[3]],
		"/admin/overview": [43,[3]],
		"/admin/system-information": [44,[3]],
		"/admin/tags": [~45,[3]],
		"/app": [~46,[7]],
		"/app/company/card-designs": [~59,[7]],
		"/app/company/contacts": [~60,[7]],
		"/app/company/orders": [~61,[7]],
		"/app/company/overview": [~62,[7]],
		"/app/company/referral": [~63,[7]],
		"/app/company/settings": [~64,[7,9]],
		"/app/company/settings/billing-address": [~65,[7,9]],
		"/app/company/settings/company": [~66,[7,9]],
		"/app/company/settings/integrations": [~67,[7,9]],
		"/app/company/settings/invoices-and-billing": [~68,[7,9]],
		"/app/[unitId]": [~47,[7]],
		"/app/[unitId]/contacts": [~48,[7]],
		"/app/[unitId]/design": [~49,[7]],
		"/app/[unitId]/employees": [~50,[7]],
		"/app/[unitId]/orders": [~51,[7]],
		"/app/[unitId]/overview": [~52,[7]],
		"/app/[unitId]/pass-design": [~53,[7]],
		"/app/[unitId]/profile": [~54,[7]],
		"/app/[unitId]/settings": [~55,[7,8]],
		"/app/[unitId]/settings/employees": [~56,[7,8]],
		"/app/[unitId]/settings/profile": [~57,[7,8]],
		"/app/[unitId]/settings/unit": [~58,[7,8]],
		"/callback/[type]": [~69],
		"/(auth)/(auth-guard)/confirm-email-change": [~14,[2]],
		"/get-started": [~70,[10]],
		"/get-started/1-choose-type": [71,[10]],
		"/get-started/2-setup-profile": [72,[10]],
		"/get-started/3-setup-company-profile": [~73,[10]],
		"/get-started/4-agree-terms": [~74,[10]],
		"/(auth)/(no-auth-guard)/login": [~19,[2]],
		"/(auth)/(auth-guard)/logout": [~15,[2]],
		"/profile/(protected)/analytics": [~75,[11]],
		"/profile/(protected)/billing-address": [~76,[11]],
		"/profile/(protected)/contacts": [~77,[11]],
		"/profile/(protected)/contacts/[...contactId]": [~78,[11]],
		"/profile/deleted": [84,[11]],
		"/profile/(protected)/invoice-and-billing": [~79,[11]],
		"/profile/premium-feature": [85,[11]],
		"/profile/(protected)/settings": [~80,[11]],
		"/profile/(protected)/tags": [~81,[11]],
		"/profile/(protected)/tags/[...tagId]": [~82,[11]],
		"/profile/[...profileId]": [~83,[11]],
		"/(auth)/(auth-guard)/redirect-user": [~16,[2]],
		"/(auth)/(auth-guard)/refresh-session": [~17,[2]],
		"/(auth)/(no-auth-guard)/register": [~20,[2]],
		"/(auth)/(no-auth-guard)/register/confirm": [21,[2]],
		"/(auth)/(no-auth-guard)/request-magic-link": [~22,[2]],
		"/(auth)/(no-auth-guard)/reset-password": [~23,[2]],
		"/(auth)/set-password": [~24,[2]],
		"/supplier": [~86,[12]],
		"/supplier/orders": [~87,[12]],
		"/t/[tagId]": [~88],
		"/(auth)/(auth-guard)/update-account": [~18,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';